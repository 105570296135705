.loader{
  position:absolute;
  background-color: rgba(255,255,255,0.8);
  width:100%;
  height:100vh;
  z-index:9;
  top:0px;
  left:0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
