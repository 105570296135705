@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

:root {
  --background: #f0f2f5;
  --red: #e52e4d;
  --blue: #5429cc;
  --green: #1ca48f;
  --orange: #c4890c;
  --text-title: #363f5f;
  --text-body: #969cb3;
  --shape: #ffffff;
  --primary-color0: #2a9eda;
  --blue-light: #4885ed;
  --blue-primary-color: #172b44;
  --blue-primary-color-1: #24436a;
  --dark-primary-color: #4444c0;
  --dark-primary-color-1: #2c2b3c;
  --gray: #707070;
  --white: #fbfbfb;
}

body {
  font: 400 14px Roboto, sans-serif;
  background: var(--background);
  -webkit-font-smothing: antialiased;
  background-color: var(--background);
  color: var(--gray);
}


button,
textarea {
  font: 400 16px Roboto, sans-serif;
}

h1 {
  color: var(--gray);
}

button {
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
}

#link-ref {
  border-radius: 4px;
  color: var(--white);
  text-align: center;
  max-width: 10%;
  padding: 16px;
  text-decoration: none;
  background-color: var(--green);
  transition: filter 0.2s;
}

#link-ref:hover {
  filter: brightness(0.9);
}
